import { Box, Card, CardMedia, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";
import { ConditionalWrapper } from "./conditional-wrapper";

const SKEW = "skewX(-7.125deg)";
const SKEW_REV = "skewX(7.125deg)";
const TILE_HEIGHT = 480;

interface GalleryProps {
  children: React.ReactNode;
}

export function Gallery(props: GalleryProps) {
  return (
    <Box display="flex" margin={`0 -${TILE_HEIGHT / 16}px`}>
      {props.children}
    </Box>
  );
}

const useTileStyles = makeStyles<void, "hobbyOverlay">()(
  (theme, params, classes) => ({
    hobbyCard: {
      flex: 1,
      height: TILE_HEIGHT,
      position: "relative",
      transform: SKEW,
      transition: theme.transitions.create("flex"),
      border: 0,
      [`&:hover .${classes.hobbyOverlay}`]: {
        opacity: 1,
      },
      "&:hover $hobbyImage": {
        // transform: `scale(1) ${SKEW_REV}`,
      },
      "&:hover": {
        flex: 3.5,
      },
    },
    hobbyImage: {
      transform: `scale(1) ${SKEW_REV}`,
      margin: `0 -${TILE_HEIGHT / 16}px`,
      height: "100%",
      transition: theme.transitions.create(["transform", "visibility"]),
    },
    hobbyOverlay: {
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      opacity: 0,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 100,
      background: "rgba(0,0,0,0.25)",
      color: "white",
      padding: theme.spacing(1, 2),
      transition: theme.transitions.create("opacity"),
    },
    hobbyText: {
      transform: SKEW_REV,
      textShadow: "0px 0px 4px #000",
    },
  })
);

interface GalleryItemProps {
  title: string;
  image: string;
  color: string;
  link?: string;
  children?: React.ReactNode;
}

export function GalleryItem(props: GalleryItemProps) {
  const { classes } = useTileStyles();
  return (
    <Card
      className={classes.hobbyCard}
      style={{ backgroundColor: props.color }}
    >
      <ConditionalWrapper
        condition={Boolean(props.link)}
        wrapper={(children) => (
          <a href={props.link} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        )}
      >
        <div className={classes.hobbyOverlay}>
          <Typography
            variant="h6"
            component="div"
            color="inherit"
            align="center"
            className={classes.hobbyText}
          >
            {props.title}
          </Typography>
          {props.children}
        </div>
      </ConditionalWrapper>
      <CardMedia className={classes.hobbyImage} image={props.image} />
    </Card>
  );
}
