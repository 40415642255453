import React from "react";
import Layout from "../components/layout";
import { hobbies } from "./hobbies.data";
import { Gallery, GalleryItem } from "../components/gallery";
import { useTranslation } from "react-i18next";

export default function HobbiesPage() {
  const { t } = useTranslation();
  return (
    <Layout title="Hobbies" fullWidth>
      <Gallery>
        {hobbies.map((hobby, i) => (
          <GalleryItem
            key={i}
            color={hobby.color}
            title={t(hobby.title)}
            image={hobby.image}
          />
        ))}
      </Gallery>
    </Layout>
  );
}
