import { Chip } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React from "react";
import { getColorForIndustry } from "../career-entry";
import { CareerData } from "../career.data";
import clsx from "clsx";
import { CvAvatar } from "../career-icon";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles()((theme) => ({
  root: {
    overflowX: "hidden",
    display: "flex",
    alignItems: "center",
  },
  chip: {
    height: "100%",
    width: "100%",
    justifyContent: "start",
  },
  text: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

interface TimelineEntryProps {
  entry: CareerData;
  row: string | number;
  gridColumnStart: number;
  gridColumnEnd: number;
  onClick: () => void;
  isActive: boolean;
}

export const TimelineEntry = (props: TimelineEntryProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation("cv");
  return (
    <div
      className={clsx(classes.root)}
      style={{
        gridRow: props.row,
        gridColumn: `${props.gridColumnStart} / ${props.gridColumnEnd}`,
      }}
    >
      <Chip
        onClick={props.onClick}
        classes={{ root: classes.chip }}
        icon={<CvAvatar type={props.entry.type} width="40" />}
        label={`${t(props.entry.position)} @ ${t(props.entry.company)}`}
        color={
          props.isActive ? getColorForIndustry(props.entry.industry) : "default"
        }
        clickable
      ></Chip>
    </div>
  );
};
