import moment from "moment";

interface TimeLineEntry {
  startDate: string;
  endDate?: string;
}

export function sortTimeLine<T extends TimeLineEntry>(entries: T[]): T[] {
  function compare(a: string, b: string) {
    return moment(a, "YYYY/MM/DD").isBefore(moment(b, "YYYY/MM/DD")) ? 1 : -1;
  }
  return entries.sort((a, b) => {
    if (a.endDate && b.endDate) {
      return compare(a.endDate, b.endDate);
    } else if (a.endDate) {
      return 1;
    } else if (b.endDate) {
      return -1;
    } else {
      return compare(a.startDate, b.startDate);
    }
  });
}
