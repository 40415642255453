import React, { FunctionComponent } from "react";
import { SchoolSharp as EducationIcon } from "@mui/icons-material";
import WorkIcon from "@mui/icons-material/WorkSharp";
import VolunteerIcon from "@mui/icons-material/MoneyOff";
import { SvgIconProps } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import clsx from "clsx";

interface CvAvatarProps {
  type: "work" | "education" | "non-profit";
  color?: SvgIconProps["color"];
  width?: "default" | "40";
}

const useStyles = makeStyles()({
  medium: {
    width: 40,
  },
});

export const CvAvatar: FunctionComponent<CvAvatarProps> = ({
  type,
  color,
  width,
}) => {
  const { classes } = useStyles();
  const className = clsx({ [classes.medium]: width === "40" });
  switch (type) {
    case "education":
      return <EducationIcon color={color} className={className} />;
    case "work":
      return <WorkIcon color={color} className={className} />;
    case "non-profit":
      return <VolunteerIcon color={color} className={className} />;
  }
};
