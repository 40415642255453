import { useState } from "react";
import { ThemeOptions, useMediaQuery } from "@mui/material";

/* PRIMARY */
// #FF7711 orange

/* SECONDARY */
// #DDF45B green yellow
// #C6F91F lime
// #7D387D maximum purple
// #187795 teal blue

// #3073D4

/* BLACK */
// #27474E dark slate gray
// #3A2D32 black coffee
// #2C0703 dark sienna

const darkTheme: ThemeOptions = {
  palette: {
    primary: {
      main: "#FF7711",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#DDF45B",
      contrastText: "#303030",
    },
    background: {
      paper: "#424242",
      default: "#303030",
    },
    mode: "dark",
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          border: "2px solid rgba(255,255,255,0.7)",
          backgroundColor: "transparent",
        },
      },
      defaultProps: {
        elevation: 0,
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiAvatar: {
      defaultProps: {
        variant: "square",
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: '"Futura PT", Futura, Jost, "Trebuchet MS", Arial, sans-serif',
    fontSize: 16,
    h3: {
      fontWeight: "lighter",
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: "normal",
    },
    h6: {
      fontWeight: "normal",
    },
  },
};

const lightTheme: ThemeOptions = {
  ...darkTheme,
  palette: {
    ...darkTheme.palette,
    background: {
      paper: "#ffffff",
      default: "#fafafa",
    },
    mode: "light",
  },
  components: {
    ...darkTheme.components,
    MuiCard: {
      styleOverrides: {
        root: {
          border: "2px solid rgba(0, 0, 0, 0.54)",
          backgroundColor: "transparent",
        },
      },
    },
  },
};

export const useDarkMode: () => [ThemeOptions, () => void] = () => {
  const prefersLightMode = useMediaQuery("(prefers-color-scheme: light)");
  const [theme, setTheme] = useState<ThemeOptions>(
    prefersLightMode ? lightTheme : darkTheme
  );
  const mode = theme.palette?.mode;
  const toggleDarkMode = () => {
    setTheme(mode === "light" ? darkTheme : lightTheme);
  };
  return [theme, toggleDarkMode];
};
