export interface SkillCategoryData {
  title: string;
  entries: string[];
}

export const skills: SkillCategoryData[] = [
  {
    title: "Programming Languages",
    entries: ["JavaScript", "TypeScript", "HTML", "CSS", "SQL", "Java"],
  },
  {
    title: "Libraries & Frameworks",
    entries: [
      "React",
      "Redux",
      "Nest JS",
      "Material UI",
      "CSS in JS",
      "Webpack",
      "Bootstrap",
    ],
  },
  {
    title: "Tools",
    entries: [
      "GitLab CI",
      "Jira",
      "Docker",
      "Confluence",
      "VS Code",
      "AWS",
      "Fastlane",
    ],
  },
  {
    title: "Software Developer Skills",
    entries: [
      "Software Architecture",
      "Analytic Thinking",
      "Domain driven design",
      "Scrum",
      "Refactoring",
      "Clean Coding",
      "Microservices",
      "Code Reviews",
      "Agile Software Development",
    ],
  },
];
