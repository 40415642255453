export const resumeEnglish = {
  resume_title: "CV",
  uulm_name: "Ulm University",
  uulm_tutor_position: "Tutor",
  uulm_tutor_description:
    "As a tutor for computer science I had to prepare lessons for small study groups. We gathered around a computer for 45 minutes. I explained (or let a student explain) the solutions of their homeworks which I had a closer look at during the preparation. The students had time to ask me some questions concerning their tasks or the lectures.",
  uulm_degree: "B.Sc. Media Informatics",
  casavi_name: "casavi GmbH",
  casavi_position: "Full Stack Web Developer",
  casavi_description_1: `As part of my bachelor thesis I created a native Android app for this
    startup. When I started my job at casavi as a web developer, the company
    migrated their existing code base from Jade to React in order to have a
    SPA that can be wrapped into a Cordova dress. Also, the software's
    backend used Node.js. So, I had to get familiar with JavaScript.
    With the amount of customers rising new use cases came up for the
    software. I put in some ideas for the architecture, e.g. for a scalable access management.`,
  casavi_description_2: `After the first app launched in the stores,
    the demand for white-label apps increased and the building and deployment process couldn't be done manually anymore.
    That's when I gave Fastlane a shot and set together with a colleague from the customer department to design a maintainable concept for new and existing customer apps.`,
  casavi_description_3: `Having a Java background, I did some research of how to upgrade the existing code base by introducing type-safety to it and finally decided to migrate a microservice to TypeScript.`,
  casavi_description_4: `In summer 2018 I got the opportunity to setup an entirely new software product.
I was responsible for choosing the architecture and the maintainance.
We started off with Material UI in the frontend and Express in the backend.
In 2019 I decided to go with domain driven design and we migrated to NestJS by the end of the year.`,
  tum_name: "Technical University of Munich",
  tum_degree: "B.Sc. Health Science",
  tum_walkimuc_position: "Research Intern",
  tum_walkimuc_employer:
    "Associate Professorship of Didactics in Sport and Health (TUM)",
  tum_walkimuc_description: `Member of the project WALKI-MUC. The project aims to evaluate and improve
      the walkability in some of Munich's neighborhoods. The walkability was
      collected from the perceptives of local children and adolescents. The chosen
      methods were participative. My tasks in the project included preparing and
      co-conducting workshops and walking interviews, and analyzing data.`,

  self_employed: "Self-employed",
  self_employed_position: "Software Engineer",
  self_employed_description: `I used the experience I gathered in my studies and my previous job to
      council companies in matters of software projects and to implement those projects.
      The technologies I used were basically those I already knew and which I made good experiences with.
      But when other technologies were more sensible, I taught myself to use and integrate them.
      I mainly refactored already existing projects to be able to expand them and make them easily adaptable,
      so that the customers don't have hard times to get any changes done once I'm not involved in the project anymore.`,

  indeterminate_duration: "{{ start, datetime }} – today",
  determinate_duration: "{{ start, datetime }} – {{ end, datetime }}",
  m24_internship_position: "Corporate Health Management Intern",
  m24_internship_description: `As a member of the movement24 team, I support my colleagues in
      implementing corporate health projects for our customers.
      With the company strongly positioned in digital products,
      I consulted on usability topics and created content for various online tools,
      e.g. writing screenplays, acting and cutting videos`,
  m24_minijob_position: "Digital Allrounder (minijob)",
  m24_parttime_position: "Digital Allrounder (part-time)",
  m24_parttime_description: `My tasks include editing videos, conducting physical activity courses,
      and digitizing internal workflows, e.g. by using a low-code solution.`,
};

export const resumeGerman: typeof resumeEnglish = {
  resume_title: "Lebenslauf",
  uulm_name: "Universität Ulm",
  uulm_tutor_position: "Tutor",
  uulm_tutor_description: `Als Informatik-Tutor bereitete ich Unterrichtseinheiten für kleine Gruppen aus Nicht-Informatik-Studierenden vor.
Jede Einheit dauerte 45 Minuten und fand in einem Computerraum statt.
Ich oder ein Studierender erklärte die Lösung zu den Hausaufgaben, die ich in Vorbereitung auf den Unterricht durchlas.
Die Studierenden hatten die Möglichkeit, mir Fragen zu ihren Aufgaben oder den Vorlesungseinheiten zu stellen.`,
  uulm_degree: "B.Sc. Medieninformatik",
  casavi_name: "casavi GmbH",
  casavi_position: "Fullstack-Web-Entwickler",
  casavi_description_1: `Im Rahmen meiner Bachelorarbeit habe ich eine native Android-App für dieses Startup entwickelt.
Als ich meinen Job bei casavi als Webentwickler antrat, migrierte das Unternehmen seine bestehende Code-Basis von Jade zu React,
um eine SPA zu scaffen, die von einer Cordova-App ummantelt werden kann. Außerdem wurde im Backend der Software Node.js verwendet.
Ich musste mich also mit JavaScript vertraut machen. Mit dem Anstieg der Kundenzahl ergaben sich neue Anwendungsfälle für die Software.
Ich brachte einige Ideen für die Architektur ein, z.B. für ein skalierbares Zugriffsmanagement.`,
  casavi_description_2: `Nachdem die erste App in den Stores veröffentlicht wurde,
stieg die Nachfrage nach White-Label-Apps und der Erstellungs- und Aktualisierungssprozess war nicht mehr manuell zu bewältigen.
Das war der Zeitpunkt, an dem ich Fastlane ausprobierte und mich mit einem Kollegen aus der Kundenabteilung zusammensetzte,
um ein wartbares Konzept für neue und bestehende Kunden-Apps zu entwerfen.`,
  casavi_description_3: `Da ich einen Hintergrund in Java habe, recherchierte ich,
  wie man die bestehende Codebasis durch die Einführung von Typsicherheit verbessern kann und entschied mich schließlich,
  einen Microservice auf TypeScript umzustellen.`,
  casavi_description_4: `Im Sommer 2018 bekam ich die Gelegenheit, ein völlig neues Softwareprodukt aufzusetzen.
Ich war für die Auswahl der Architektur und die Wartung verantwortlich. Zum Einsatz kamen Material UI im Frontend und Express im Backend.
Im Jahr 2019 entschied ich mich für Domain-driven Design und wir migrierten bis Ende des Jahres zu NestJS.`,
  tum_name: "Technische Universität München",
  tum_degree: "B.Sc. Gesundheitswissenschaft",
  tum_walkimuc_position: "Praktikant Forschung",
  tum_walkimuc_employer: "Professur für Sport- und Gesundheitsdidaktik (TUM)",
  tum_walkimuc_description: `Mitglied des Projekts WALKI-MUC.
Das Projekt zielt auf die Bewertung und Verbesserung die Bewegungsfreundlichkeit in einigen Münchner Stadtteilen zu bewerten und zu verbessern.
Die Bewegungsfreundlichkeit wurde aus der Sicht von Münchner Kindern und Jugendlichen erhoben. Die gewählten Methoden waren partizipativ.
Zu meinen Aufgaben im Projekt gehörten die Vorbereitung und Durchführung von Workshops und Walking-Interviews sowie die Analyse der Daten.`,
  self_employed: "Selbstständig",
  self_employed_position: "Software-Ingenieur",
  self_employed_description: `Ich nutzte die Erfahrungen aus meinem Studium und meiner früheren Tätigkeit, um Unternehmen in Belange rundum Software-Projekte zu beraten und diese umzusetzen.
Dabei habe ich im Wesentlichen die Technologien verwendet, die ich bereits kannte und mit denen ich gute Erfahrungen gemacht habe.
Wenn aber andere Technologien sinnvoller erschienen, eignete ich mir diese an.
Bei der Umsetzung war mir wichtig, den Code leicht verständlich und modular zu gestalten,
damit die Kunden keine Schwierigkeiten haben, selbst Änderungen vorzunehmen, sobald ich nicht mehr an dem Projekt beteiligt bin.`,

  indeterminate_duration: "{{ start, datetime }} – heute",
  determinate_duration: "{{ start, datetime }} – {{ end, datetime }}",
  m24_internship_position: "Praktikant Betriebliches Gesundheitsmanagement",
  m24_internship_description: `Als Teil des movement24-Teams unterstützte ich meine Kollegen bei der Umsetzung von Gesundheitsprojekten bei Kunden.
      Da das Unternehmen stark auf digitale Produkte setzt,
      beriet ich bei Usability-Themen und erstellte Inhalte für verschiedene Online-Tools,
      z.B. durch das Schreiben von Drehbüchern und das Bearbeiten von Videos`,
  m24_minijob_position: "Digitaler Allrounder (Minijob)",
  m24_parttime_position: "Digitaler Allrounder (Teilzeit)",
  m24_parttime_description: `Zu meinen Aufgaben gehören das Durchführen von Fitnesskursen, der Videoschnitt
      und das Digitalisieren interner Prozesse, z.B. durch den Einsatz einer Low-Code-Lösung.`,
};
