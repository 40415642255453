import React from "react";
import {
  Box,
  IconButton,
  SvgIcon,
  useTheme,
  Divider,
  Tooltip,
  Link,
  Menu,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import EmailIcon from "@mui/icons-material/MailOutlineSharp";
import DarkThemeIcon from "@mui/icons-material/Brightness4Sharp";
import LightThemeIcon from "@mui/icons-material/BrightnessHighSharp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LanguageIcon from "@mui/icons-material/Language";
import { ReactComponent as GitLabIcon } from "./images/icon-gitlab.svg";
import { useTranslation } from "react-i18next";

interface HeaderMenuProps {
  onThemeChange: () => void;
}

const useStyles = makeStyles()((theme) => ({
  root: {
    position: "absolute",
    padding: theme.spacing(0, 2),
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "flex-end",
    zIndex: theme.zIndex.appBar,
  },
}));

export const HeaderMenu = (props: HeaderMenuProps) => {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const [localeAnchorEl, setLocaleAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const localeOpen = Boolean(localeAnchorEl);
  const handleLocaleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLocaleAnchorEl(event.currentTarget);
  };
  const handleLocaleClose = (locale: string) => {
    setLocaleAnchorEl(null);
    i18n.changeLanguage(locale);
  };
  return (
    <Box component={"nav"} className={classes.root}>
      <Tooltip title={t("menu_contact")}>
        <Link
          color="inherit"
          component={IconButton}
          href="mailto:mail@christianfoerg.de"
        >
          <EmailIcon />
        </Link>
      </Tooltip>
      <Tooltip title={t("menu_linkedin")}>
        <Link
          color="inherit"
          component={IconButton}
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/christian-f%C3%B6rg-344482220/"
        >
          <SvgIcon component={LinkedInIcon} />
        </Link>
      </Tooltip>
      <Tooltip title={t("menu_gitlab")}>
        <Link
          color="inherit"
          component={IconButton}
          target="_blank"
          rel="noopener noreferrer"
          href="https://gitlab.com/christianfoerg"
        >
          <SvgIcon component={GitLabIcon} />
        </Link>
      </Tooltip>
      <Divider orientation="vertical" flexItem />
      <Tooltip
        title={
          theme.palette.mode === "light"
            ? t("menu_dark_mode")
            : t("menu_light_mode")
        }
      >
        <IconButton color="inherit" onClick={props.onThemeChange}>
          {theme.palette.mode === "light" ? (
            <DarkThemeIcon />
          ) : (
            <LightThemeIcon />
          )}
        </IconButton>
      </Tooltip>
      <Tooltip title={t("menu_language")}>
        <IconButton
          id="locale-button"
          color="inherit"
          aria-controls={localeOpen ? "locale-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={localeOpen ? "true" : undefined}
          onClick={handleLocaleClick}
        >
          <LanguageIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="locale-menu"
        anchorEl={localeAnchorEl}
        open={localeOpen}
        onClose={handleLocaleClose}
        MenuListProps={{
          "aria-labelledby": "locale-button",
        }}
      >
        <MenuItem onClick={() => handleLocaleClose("de")}>Deutsch</MenuItem>
        <MenuItem onClick={() => handleLocaleClose("en")}>English</MenuItem>
      </Menu>
    </Box>
  );
};
