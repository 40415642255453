import React from "react";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import HomePage from "./cv/home";
import HobbiesPage from "./cv/hobbies";
import LanguagesPage from "./cv/languages";
import CareerPage from "./cv/career";
import { HeaderMenu } from "./cv/menu";
import { useDarkMode } from "./theme";
import SkillsPage from "./cv/skills";
import PortfolioPage from "./cv/portfolio";
import "./i18n";

function App() {
  const [theme, toggleDarkMode] = useDarkMode();
  const themeConfig = createTheme(theme);
  return (
    <React.Fragment>
      <ThemeProvider theme={themeConfig}>
        <CssBaseline />
        <HeaderMenu onThemeChange={toggleDarkMode} />
        <main>
          <HomePage />
          <CareerPage />
          <SkillsPage />
          <PortfolioPage />
          <LanguagesPage />
          <HobbiesPage />
        </main>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
