import guitar from "./images/pexels-rene-asmussen-1539789.jpeg";
import piano from "./images/pexels-pixabay-210764.jpeg";
import drums from "./images/drums-matthijs-smit-unsplash.jpg";
import fitness from "./images/fitness-ryan-de-hamer-unsplash.jpg";
import design from "./images/pexels-alex-fu-1188751.jpeg";

export interface Hobby {
  title: string;
  image: string;
  color: string;
}

export const hobbies: Hobby[] = [
  {
    title: "hobby_guitar",
    image: guitar,
    color: "rgb(162 107 80)",
  },
  {
    title: "hobby_piano",
    image: piano,
    color: "rgb(229 202 181)",
  },
  {
    title: "hobby_drums",
    image: drums,
    color: "#654632",
  },
  {
    title: "hobby_fitness",
    image: fitness,
    color: "#75653e",
  },
  {
    title: "hobby_media_design",
    image: design,
    color: "rgb(21 22 23)",
  },
];
