import React from "react";
import { Container } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import clsx from "clsx";
import { SkewedTextBox } from "./SkewedTextBox";

interface LayoutProps {
  title: string;
  fullWidth?: boolean;
  children: React.ReactNode;
}

const useStyles = makeStyles()((theme) => ({
  header: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(7),
  },
  section: {
    padding: theme.spacing(4, 0, 8),
    "&:nth-of-type(even)": {
      background: theme.palette.background.default,
    },
    "&:nth-of-type(odd)": {
      background: theme.palette.background.paper,
    },
  },
  fullWidth: {
    overflowX: "hidden",
  },
}));

export default function Layout({ title, fullWidth, children }: LayoutProps) {
  const { classes } = useStyles();
  return (
    <div className={clsx(classes.section, { [classes.fullWidth]: fullWidth })}>
      <Container
        maxWidth={fullWidth ? undefined : "md"}
        disableGutters={fullWidth}
      >
        <div className={classes.header}>
          <SkewedTextBox variant="h3" color="secondary">
            {title}
          </SkewedTextBox>
        </div>
        <div>{children}</div>
      </Container>
    </div>
  );
}
