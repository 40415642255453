import moment from "moment";
import { CareerData } from "../career.data";

const RAW_FORMAT = "YYYY/MM/DD";

export function getMonthList(careerList: CareerData[]) {
  const nextMonth = moment().startOf("month").add(1, "month");
  const firstMonth = moment
    .min(careerList.map((c) => moment(c.startDate, RAW_FORMAT)))
    .startOf("month");
  const diff = nextMonth.diff(firstMonth, "month");
  const months = [];
  for (let i = 1; i <= diff; i++) {
    months.push(moment(nextMonth).subtract(i, "month"));
  }
  return months;
}

export function getColumnForMonth(input?: string): number {
  const now = moment().startOf("month");
  const date = input ? moment(input, RAW_FORMAT) : now;
  date.startOf("month");
  return now.diff(date, "month") + 1;
}

interface YearColumns {
  year: number;
  start: number;
  end: number;
}

export function getColumnsForYears(careerList: CareerData[]): YearColumns[] {
  const months = getMonthList(careerList);
  return months.reduce<YearColumns[]>((result, month, index) => {
    const year = month.get("year");
    const entry = result.find((e) => e.year === year);
    if (!entry) {
      result.push({
        year: year,
        start: index + 1,
        end: index + 2,
      });
    } else {
      result[result.length - 1] = {
        year: year,
        start: entry.start,
        end: index + 2,
      };
    }
    return result;
  }, []);
}
