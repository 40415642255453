import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { ReactComponent as Background } from "./images/background.svg";
import { SkewedTextBox } from "../components/SkewedTextBox";

const useStyles = makeStyles()((theme) => ({
  root: {
    position: "relative",
    padding: theme.spacing(6, 2),
    minHeight: "100vh",
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    position: "relative",
    width: 313,
    height: 154,
  },
  sub: {
    position: "absolute",
    left: 154,
    top: 72,
  },
  backgroundImage: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    zIndex: -1,
    "& polygon": {
      fill: theme.palette.primary.main,
    },
  },
}));

const HomePage = () => {
  const { classes } = useStyles();
  return (
    <Box className={classes.root}>
      <Background
        className={classes.backgroundImage}
        preserveAspectRatio="xMidYMid slice"
      />
      <Box component={"h2"} className={classes.content}>
        <SkewedTextBox color="primary" variant="h2" component={"span"}>
          Christian
        </SkewedTextBox>
        <SkewedTextBox
          color="secondary"
          variant="h2"
          component={"span"}
          className={classes.sub}
        >
          Förg
        </SkewedTextBox>
      </Box>
    </Box>
  );
};

export default HomePage;
