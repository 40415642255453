import { Box } from "@mui/material";
import React, { useState } from "react";
import Layout from "../components/layout";
import { CareerData, careerList } from "./career.data";
import { sortTimeLine } from "./time-line";
import TimelineList from "./timeline/timeline-list";
import TimelineShowcase from "./timeline/timeline-showcase";
import { useTranslation } from "react-i18next";

export default function CareerPage() {
  const entries = sortTimeLine(careerList);
  const [selectedId, selectId] = useState(entries[0].id);
  const entry = entries.find((e) => e.id === selectedId) as CareerData;

  const { t } = useTranslation();
  return (
    <Layout title={t("cv:resume_title")}>
      <TimelineList
        entries={entries}
        onSelect={selectId}
        selectedId={selectedId}
      />
      <Box mb={4} />
      <TimelineShowcase entry={entry} />
    </Layout>
  );
}
