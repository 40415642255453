import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import clsx from "clsx";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "grid",
    gridGap: theme.spacing(1),
  },
  bullet: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "currentColor",
    transform: "skewX(-7.125deg)",
    gridRow: 1,
    height: theme.spacing(2),
  },
  primary: {
    color: theme.palette.primary.main,
  },
  secondary: {
    color: theme.palette.secondary.main,
  },
  filled: {
    backgroundColor: "currentColor",
  },
}));

interface RatingProps {
  color?: "primary" | "secondary";
  value: number;
  total: number;
}

const Rating = (props: RatingProps) => {
  const { color = "primary", value, total } = props;
  const { classes } = useStyles();
  return (
    <Box className={classes.root}>
      {new Array(total).fill(0).map((_, i) => (
        <Box
          key={i}
          className={clsx(classes.bullet, classes[color], {
            [classes.filled]: i < value,
          })}
        />
      ))}
    </Box>
  );
};

export default Rating;
