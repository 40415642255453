import React from "react";
import Layout from "../components/layout";
import {
  Typography,
  Avatar,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Rating from "../components/rating";
import { languages } from "./languages.data";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles()((theme) => ({
  grid: {
    display: "grid",
    gridGap: theme.spacing(2, 2),
    gridTemplateColumns: "auto auto 1fr",
    alignItems: "center",
  },
  flag: {
    marginRight: theme.spacing(2),
    width: theme.spacing(4),
    height: theme.spacing(3),
    transform: "skewX(-7.125deg)",
  },
}));

const LanguagesPage = () => {
  const { classes } = useStyles();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  return (
    <Layout title={t("language_title")}>
      <div className={classes.grid}>
        {languages.map((language) => (
          <React.Fragment key={language.code}>
            <Avatar className={classes.flag} src={language.flag} alt={""}>
              {language.code}
            </Avatar>
            <Box display="flex" alignItems="center">
              <Box>
                <Typography variant="body1">{t(language.name)}</Typography>
                <Typography variant="caption">
                  {t(language.levelKey)}
                </Typography>
              </Box>
            </Box>
            <Rating
              value={language.level * (isXS ? 1 : 2)}
              total={isXS ? 6 : 12}
            />
          </React.Fragment>
        ))}
      </div>
    </Layout>
  );
};

export default LanguagesPage;
