import React, { FunctionComponent } from "react";
import { Box, BoxProps } from "@mui/material";
import { makeStyles } from "tss-react/mui";

interface ChipContainerProps extends BoxProps {
  children: React.ReactNode;
}

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    margin: theme.spacing(-0.5),
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

export const ChipContainer: FunctionComponent<ChipContainerProps> = (props) => {
  const { classes } = useStyles();
  return <Box {...props} className={classes.root} />;
};
