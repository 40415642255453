import React from "react";
import Layout from "../components/layout";

import WebsiteImage from "./images/background.svg";
import BpmImage from "./images/pexels-florian-g-7220729.jpg";
import GerrymanderingImage from "./images/map-g14aa14049_1920.jpg";
// import TunerImage from "./images/guitar-g6aee8792c_1920.jpg";
import { Box, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { ConditionalWrapper } from "../components/conditional-wrapper";
import { SkewedTextBox } from "../components/SkewedTextBox";
import { ChipContainer } from "../components/chip-container";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles()({
  outer: {
    flex: 0,
    height: 240,
    minWidth: 480,
    maxWidth: 600,
    border: 0,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  inner: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
});

interface PortfolioItemProps {
  title: string;
  image: string;
  color: string;
  stack: string[];
  link?: string;
  children?: React.ReactNode;
}

const PortfolioItem = (props: PortfolioItemProps) => {
  const { classes } = useStyles();
  return (
    <div
      className={classes.outer}
      style={{
        backgroundColor: props.color,
        backgroundImage: `url(${props.image})`,
      }}
    >
      <ConditionalWrapper
        condition={!!props.link}
        wrapper={(children) => (
          <a href={props.link} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        )}
      >
        <div className={classes.inner}>
          <SkewedTextBox variant="h4">{props.title}</SkewedTextBox>
          <ChipContainer paddingTop={1}>
            {props.stack.map((entry, i) => (
              <SkewedTextBox key={i} variant="caption" color="secondary">
                {entry}
              </SkewedTextBox>
            ))}
          </ChipContainer>
        </div>
      </ConditionalWrapper>
    </div>
  );
};

const PortfolioPage = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Layout title="Portfolio" fullWidth>
      <Box display="flex" flexWrap="wrap" justifyContent="center" gap="16px">
        <PortfolioItem
          title={t("portfolio_title")}
          image={WebsiteImage}
          color={theme.palette.background.default}
          stack={["TypeScript", "React", "Material UI", "i18next"]}
        >
          TypeScript, React, Material UI
        </PortfolioItem>
        <PortfolioItem
          title="BPM Tapper"
          image={BpmImage}
          color="#B4B3AD"
          link="https://bpm.christianfoerg.de"
          stack={["TypeScript", "Angular", "SCSS"]}
        >
          TypeScript, Angular, SCSS
        </PortfolioItem>
        <PortfolioItem
          title="Gerrymandering"
          image={GerrymanderingImage}
          color="#CB9701"
          link="https://districts.christianfoerg.de"
          stack={["TypeScript", "MVC", "OpenLayers"]}
        >
          “Vanilla” TypeScript, MVC, OpenLayers
        </PortfolioItem>
        {/* <GalleryItem
            title="Guitar Tuner"
            image={TunerImage}
            color="transparent"
            link="https://tuner.christianfoerg.de"
          /> */}
      </Box>
    </Layout>
  );
};

export default PortfolioPage;
