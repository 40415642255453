import React from "react";
import { Card, CardContent, CardHeader, Chip, Typography } from "@mui/material";
import { CareerData, displayDuration } from "../career.data";
import { ChipContainer } from "../../components/chip-container";
import { CvAvatar } from "../career-icon";
import { useTranslation } from "react-i18next";

interface TimelineShowcaseProps {
  entry: CareerData;
}

export default function TimelineShowcase({ entry }: TimelineShowcaseProps) {
  const { t } = useTranslation("cv");
  return (
    <Card>
      <CardHeader
        avatar={<CvAvatar type={entry.type} />}
        title={`${t(entry.position)} (${displayDuration(entry)})`}
        subheader={t(entry.company)}
      />
      {entry.description.length ? (
        <CardContent>
          {entry.description.map((tKey, i) => (
            <Typography key={i} align="justify">
              {t(tKey)}
            </Typography>
          ))}
        </CardContent>
      ) : null}
      {entry.tags ? (
        <CardContent>
          <ChipContainer>
            {entry.tags.map((tag, i) => (
              <Chip key={i} label={tag} variant="outlined" />
            ))}
          </ChipContainer>
        </CardContent>
      ) : null}
    </Card>
  );
}
