import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { CareerData } from "../career.data";
import { TimelineEntry } from "./timeline-entry";
import { getColumnForMonth, getColumnsForYears, getMonthList } from "./helper";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface StyleProps {
  months: number;
}

const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  timeline: {
    display: "grid",
    gap: "8px 0",
    overflowX: "scroll",
    gridTemplateColumns: `repeat(${props.months}, 40px)`,
    gridTemplateRows: "repeat(4,40px) [months] auto [years] auto 8px",
  },
  block: {
    background: theme.palette.action.hover,
  },
  divider: {
    borderRight: "1px solid gray",
  },
}));

interface TimelineListProps {
  entries: CareerData[];
  onSelect: (id: string) => void;
  selectedId: string;
}

export default function TimelineList({
  entries,
  selectedId,
  onSelect,
}: TimelineListProps) {
  const months = getMonthList(entries);
  const years = getColumnsForYears(entries);
  const { classes } = useStyles({ months: months.length });
  const { t } = useTranslation();
  return (
    <div className={classes.timeline}>
      {months.map((month, i) => (
        <React.Fragment key={i}>
          <div
            className={clsx({
              [classes.block]: i % 2 === 0,
            })}
            style={{ gridRow: "1 / 6", gridColumnStart: i + 1 }}
          />
          <div
            className={clsx({
              [classes.divider]: month.get("month") === 0,
            })}
            style={{ gridRow: "1 / 8", gridColumnStart: i + 1 }}
          />
          <Box
            textAlign="center"
            whiteSpace="nowrap"
            style={{ gridColumn: i + 1, gridRow: "months" }}
          >
            <Typography variant="caption">
              {t("generic_date", {
                date: month.toDate(),
                formatParams: { date: { month: "short" } },
              })}
            </Typography>
          </Box>
        </React.Fragment>
      ))}
      {years.map((year) => (
        <Box
          key={year.year}
          display="flex"
          justifyContent="center"
          style={{
            gridRow: "years",
            gridColumn: `${year.start} / ${year.end}`,
          }}
        >
          <Typography variant="caption">{year.year}</Typography>
        </Box>
      ))}
      {entries.map((entry, i) => (
        <TimelineEntry
          key={entry.id}
          isActive={entry.id === selectedId}
          entry={entry}
          row={entry.row}
          gridColumnStart={getColumnForMonth(entry.endDate)}
          gridColumnEnd={getColumnForMonth(entry.startDate) + 1}
          onClick={() => onSelect(entry.id)}
        />
      ))}
    </div>
  );
}
