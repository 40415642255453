import { resumeEnglish, resumeGerman } from "./resume";

const english = {
  menu_contact: "Contact me",
  menu_linkedin: "To my LinkedIn profile",
  menu_gitlab: "To my GitLab profile",
  menu_language: "Choose language",
  menu_light_mode: "Switch to light mode",
  menu_dark_mode: "Switch to dark mode",
  language_title: "Languages",
  language_de: "German",
  language_en: "English",
  language_nl: "Dutch",
  language_level_native: "Native",
  language_level_c1: "C1",
  language_level_a2: "A2",
  hobby_guitar: "Guitar",
  hobby_piano: "Piano",
  hobby_drums: "Drums",
  hobby_fitness: "Fitness",
  hobby_media_design: "Media Design",
  portfolio_title: "This Website",
  generic_date: "{{ date, datetime }}",
};

export type TranslationRecord = typeof english;

const german: TranslationRecord = {
  menu_contact: "Kontaktieren",
  menu_linkedin: "Zu meinem LinkedIn-Profil",
  menu_gitlab: "Zu meinem GitLab-Profil",
  menu_language: "Sprache wählen",
  menu_light_mode: "Zum Light Mode wechseln",
  menu_dark_mode: "Zum Dark Mode wechseln",
  language_title: "Sprachen",
  language_de: "Deutsch",
  language_en: "Englisch",
  language_nl: "Niederländisch",
  language_level_native: "Muttersprache",
  language_level_c1: "C1",
  language_level_a2: "A2",
  hobby_guitar: "Gitarre",
  hobby_piano: "Piano",
  hobby_drums: "Schlagzeug",
  hobby_fitness: "Fitness",
  hobby_media_design: "Medien-Design",
  portfolio_title: "Diese Website",
  generic_date: "{{ date, datetime }}",
};

export const resources = {
  en: {
    translation: english,
    cv: resumeEnglish,
  },
  de: {
    translation: german,
    cv: resumeGerman,
  },
};
