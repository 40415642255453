import React, { FunctionComponent, useState } from "react";
import { CareerData, displayDuration } from "./career.data";
import {
  CardHeader,
  Avatar,
  IconButton,
  Collapse,
  CardContent,
  Typography,
  Box,
  Card,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import clsx from "clsx";
import { CvAvatar } from "./career-icon";

export const getColorForIndustry = (industry: string) => {
  return industry === "software" ? "secondary" : "primary";
};

interface CareerEntryProps {
  entry: CareerData;
}

const useStyles = makeStyles()((theme) => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    borderRadius: 0,
    backgroundColor: "transparent",
  },
}));

export const CareerEntry: FunctionComponent<CareerEntryProps> = ({ entry }) => {
  const { classes } = useStyles();
  const [expanded, setExpanded] = useState(false);
  function handleExpandClick() {
    setExpanded(!expanded);
  }
  return (
    <Box>
      <Typography align="center" variant="overline" component="div">
        {displayDuration(entry)}
      </Typography>
      <Card>
        <CardHeader
          title={entry.position}
          subheader={entry.company}
          avatar={
            <Avatar classes={{ root: classes.avatar }}>
              <CvAvatar type={entry.type} />
            </Avatar>
          }
          action={
            entry.description.length ? (
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            ) : null
          }
        />
        <Collapse in={Boolean(entry.description.length) && expanded}>
          <CardContent>
            {entry.description.map((text, i) => (
              <Typography key={i} gutterBottom>
                {text}
              </Typography>
            ))}
          </CardContent>
        </Collapse>
      </Card>
    </Box>
  );
};
