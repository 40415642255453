import germany from "./images/Flag_of_Germany.svg";
import usa from "./images/Flag_of_the_USA.svg";
import netherlands from "./images/Flag_of_the_Netherlands.svg";

export const languages = [
  {
    name: "language_de",
    level: 6,
    levelKey: "language_level_native",
    code: "DE",
    flag: germany,
  },
  {
    name: "language_en",
    level: 5,
    levelKey: "language_level_c1",
    code: "EN",
    flag: usa,
  },
  {
    name: "language_nl",
    level: 2,
    levelKey: "language_level_a2",
    code: "NL",
    flag: netherlands,
  },
];
