import React, { FunctionComponent } from "react";
import Layout from "../components/layout";
import { Card, Grid, CardContent, Typography, Chip, Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { SkillCategoryData, skills } from "./skills.data";

interface SkillCategoryProps {
  category: SkillCategoryData;
}

const useStyles = makeStyles()((theme) => ({
  root: {
    height: "100%",
  },
  chipContainer: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    margin: theme.spacing(-0.5),
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

const SkillCategory: FunctionComponent<SkillCategoryProps> = ({ category }) => {
  const { classes } = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h4" align="center" color="primary">
          {category.title}
        </Typography>
      </CardContent>
      <CardContent>
        <Box className={classes.chipContainer}>
          {category.entries.map((entry, i) => (
            <Chip key={i} label={entry} variant="outlined" />
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

const SkillsPage = () => {
  return (
    <Layout title="Software Skills">
      <Grid container spacing={2}>
        {skills.map((skill, i) => (
          <Grid key={i} item xs={12} sm={6}>
            <SkillCategory category={skill} />
          </Grid>
        ))}
      </Grid>
    </Layout>
  );
};

export default SkillsPage;
