import React from "react";
import { Typography, TypographyProps } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import clsx from "clsx";
import { Variant } from "@mui/material/styles/createTypography";

// top 88
// left 140 + 16

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(0, 1),
    transform: "skewX(-7.125deg)",
    display: "inline-block",
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main, //"lightgray",
    color: theme.palette.secondary.contrastText,
  },
  text: {
    transform: "skewX(7.125deg)",
    fontStyle: "italic",
    fontWeight: 600,
    textAlign: "center",
    display: "inline-block",
    [theme.breakpoints.down("xs")]: {
      display: "table-caption",
    },
  },
}));

interface SkewedTextBoxProps extends TypographyProps {
  variant?: "inherit" | Variant;
  color?: "primary" | "secondary";
  className?: string;
  component?: React.ElementType;
  children: React.ReactNode;
}

export const SkewedTextBox = (props: SkewedTextBoxProps) => {
  // const color = props.color || "primary";
  const { color = "primary", className, ...rest } = props;
  const { classes } = useStyles();
  return (
    <div className={clsx(classes.root, classes[color], className)}>
      <Typography {...rest} className={classes.text} />
    </div>
  );
};
