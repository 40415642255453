import moment from "moment";
import { i18n } from "../i18n";

export interface CareerData {
  id: string;
  type: "work" | "education";
  industry: string;
  position: string;
  company: string;
  description: string[];
  startDate: string;
  endDate?: string;
  row: number;
  tags?: string[];
}

export const careerList: CareerData[] = [
  {
    id: "uulm",
    type: "education",
    industry: "software",
    position: "uulm_degree",
    company: "uulm_name",
    description: [],
    startDate: "2012/04/01",
    endDate: "2015/09/30",
    row: 4,
  },
  {
    id: "tutor",
    type: "work",
    industry: "software",
    position: "uulm_tutor_position",
    description: ["uulm_tutor_description"],
    company: "uulm_name",
    startDate: "2014/04/01",
    endDate: "2015/02/28",
    row: 3,
  },
  {
    id: "casavi",
    type: "work",
    industry: "software",
    position: "casavi_position",
    company: "casavi_name",
    description: [
      "casavi_description_1",
      "casavi_description_2",
      "casavi_description_3",
      "casavi_description_4",
    ],
    tags: [
      "NodeJS",
      "MySQL",
      "MongoDB",
      "Redis",
      "NestJS",
      "React",
      "Redux",
      "TypeScript",
      "i18n",
      "AWS",
      "Jira",
      "GitLab",
      "Docker",
    ],
    startDate: "2015/09/01",
    endDate: "2020/05/31",
    row: 3,
  },
  {
    id: "tum",
    type: "education",
    industry: "health",
    position: "tum_degree",
    company: "tum_name",
    description: [],
    startDate: "2020/10/21",
    row: 2,
  },
  {
    id: "cft",
    type: "work",
    industry: "software",
    position: "self_employed_position",
    company: "self_employed",
    description: ["self_employed_description"],
    tags: ["React", "Redux", "MUI", "Electron", "TypeScript", "Figma", "MQTT"],
    startDate: "2020/11/02",
    endDate: "2021/12/31",
    row: 3,
  },
  {
    id: "tumdidaktik",
    type: "work",
    industry: "health",
    position: "tum_walkimuc_position",
    company: "tum_walkimuc_employer",
    description: ["tum_walkimuc_description"],
    startDate: "2022/03/07",
    endDate: "2022/04/30",
    row: 1,
  },
  {
    id: "m24-intern",
    type: "work",
    industry: "health",
    position: "m24_internship_position",
    company: "movement24 GmbH",
    description: ["m24_internship_description"],
    startDate: "2022/05/01",
    endDate: "2022/07/31",
    row: 1,
  },
  {
    id: "m24-mini",
    type: "work",
    industry: "health",
    position: "m24_minijob_position",
    company: "movement24 GmbH",
    description: ["m24_parttime_description"],
    startDate: "2022/08/01",
    endDate: "2023/03/31",
    row: 1,
  },
  {
    id: "sii",
    type: "work",
    industry: "software",
    position: "Senior Software Developer",
    company: "SII Technologies",
    description: [],
    startDate: "2022/11/01",
    endDate: "2023/02/05",
    row: 3,
  },
  {
    id: "m24-midi",
    type: "work",
    industry: "health",
    position: "m24_parttime_position",
    company: "movement24 GmbH",
    description: ["m24_parttime_description"],
    startDate: "2023/04/01",
    endDate: "2023/12/31",
    row: 1,
  },
  {
    id: "cc",
    type: "work",
    industry: "software",
    position: "IT Consultant",
    company: "codecentric AG",
    description: [],
    startDate: "2024/02/01",
    row: 3,
  },
];

export function displayDuration(entry: CareerData): string {
  const start = moment(entry.startDate, "YYYY/MM/DD").toDate();
  if (!entry.endDate)
    return i18n.t("cv:indeterminate_duration", {
      start,
      formatParams: { start: { month: "short", year: "numeric" } },
    });
  const end = moment(entry.endDate, "YYYY/MM/DD").toDate();
  return i18n.t("cv:determinate_duration", {
    start,
    end,
    formatParams: {
      start: { month: "short", year: "numeric" },
      end: { month: "short", year: "numeric" },
    },
  });
}
